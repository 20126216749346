<template>
	<div>
		<page-authorization-container :page="page" show-errors-with-object>
			<b-form @submit.prevent="create">
				<b-form-group label="Scenario name">
					<b-form-input v-model="form.name" type="text" autofocus :state="getValidState($v.form.name)"></b-form-input>
					<b-form-invalid-feedback v-if="!$v.form.name.required">Required</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="!$v.form.name.hasAlphaNumeric">Must contain at least one letter or number</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group label="Weather data" :invalid-feedback="requiredFeedback($v.form.weatherDatasetID)">
					<b-form-select v-model="form.weatherDatasetID" :state="getValidState($v.form.weatherDatasetID)" @change="updateWeatherOptions">
						<optgroup v-for="(group, i) in options.weatherDatasets" :key="i" :label="group.label">
							<option v-for="(opt, j) in group.options" :key="j" :value="opt.id">{{opt.name}}</option>
						</optgroup>
					</b-form-select>
				</b-form-group>

				<b-row v-if="weatherOptions.showClimateChange">
					<b-col md>
						<b-form-group label="Climate time period">
							<b-form-select v-model="form.climateChangeTimePeriodID" @change="updateClimateTimePeriod">
								<option v-for="(opt, i) in climateTimePeriodOptions" :key="i" :value="opt.id">{{opt.name}}</option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Climate scenario">
							<b-form-select v-model="form.climateChangeScenarioID">
								<option v-for="(opt, i) in climateScenarioOptions" :key="i" :value="opt.id">{{opt.name}}</option>
							</b-form-select>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col md>
						<b-form-group label="Simulation start date" :invalid-feedback="requiredFeedback($v.form.startingSimulationDate)">
							<b-form-input v-model="form.startingSimulationDate" type="date" :state="getValidState($v.form.startingSimulationDate)"></b-form-input>
							<small class="form-text text-muted">{{weatherOptions.minDate | date('MM/DD/YYYY')}} or later</small>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Simulation end date" :invalid-feedback="requiredFeedback($v.form.endingSimulationDate)">
							<b-form-input v-model="form.endingSimulationDate" type="date" :state="getValidState($v.form.endingSimulationDate)"></b-form-input>
							<small class="form-text text-muted">{{weatherOptions.maxDate | date('MM/DD/YYYY')}} or earlier</small>
						</b-form-group>
					</b-col>
				</b-row>

				<b-form-group label="Set-up/warm-up years" :invalid-feedback="requiredFeedback($v.form.skipYears)">
					<b-form-input v-model="form.skipYears" type="number" :state="getValidState($v.form.skipYears)"></b-form-input>
				</b-form-group>

				<b-form-group label="SWAT output print setting" :invalid-feedback="requiredFeedback($v.form.printSetting)">
					<b-form-select v-model="form.printSetting" :options="options.printSettings" :state="getValidState($v.form.printSetting)"></b-form-select>
				</b-form-group>

				<b-form-group label="SWAT model version to run" :invalid-feedback="requiredFeedback($v.form.swatVersionID)">
					<b-form-select v-model="form.swatVersionID" :options="options.swatVersions" :state="getValidState($v.form.swatVersionID)"></b-form-select>
				</b-form-group>

				<div class="mt-4 mb-3">
					<save-button :saving="page.saving" text="Save Changes" class="mr-2" />
					<b-button v-if="showCancelButton" variant="secondary" @click="cancel">Cancel</b-button>
					<back-button v-if="showBackButton" class="btn btn-secondary mr-2" />
				</div>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import moment from 'moment';

	export default {
		name: 'ScenarioForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				required: true
			},
			showCancelButton: {
				type: Boolean,
				default: false
			},
			showBackButton: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false
				},
				options: {
					climateChangeScenarios: [],
					climateChangeTimePeriods: [],
					printSettings: [],
					swatVersions: [],
					weatherDatasets: []
				},
				form: this.data,
				weatherOptions: {
					selectedDataset: undefined,
					minDate: undefined,
					maxDate: undefined,
					showClimateChange: false
				}
			}
		},
		validations: {
			form: {
				name: {
					required,
					hasAlphaNumeric(value) {
						return (
							/[a-z]/.test(value) || // checks for a-z
							/[0-9]/.test(value) // checks for 0-9
						);
					}
				},
				startingSimulationDate: { required },
				endingSimulationDate: { required },
				weatherDatasetID: { required },
				swatVersionID: { required },
				printSetting: { required },
				skipYears: { required }
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/options/${this.data.projectID}`, this.getTokenHeader());
					this.log(response.data);
					this.options = response.data;
					if (!this.isUpdate) {
						this.form.weatherDatasetID = response.data.defaultWeatherDataset;
						this.form.swatVersionID = response.data.defaultSWATVersion;
						this.updateWeatherOptions(true);
					}
					else {
						this.updateWeatherOptions(false);
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async create() {
				this.page.errors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else if (moment(this.form.endingSimulationDate).isSameOrBefore(this.form.startingSimulationDate)) {
					this.page.errors.push('Simulation end date must be greater than starting date.');
				} else {
					try {
						var response;
						var scenarioID;

						if (!this.isUpdate) {
							response = await this.$http.post('scenarios', this.form, this.getTokenHeader());
							scenarioID = response.data;
							this.$router.push({ name: 'ScenarioView', params: { id: this.data.projectID, scenarioID: scenarioID } }).catch(err => {});
						} else {
							response = await this.$http.put(`scenarios/${this.data.id}`, this.form, this.getTokenHeader());
							scenarioID = this.data.id;
							this.$emit('saved', 'success');
						}

						
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			updateWeatherOptions(setDefaults = true) {
				for (var i = 0; i < this.options.weatherDatasets.length; i++) {
					var index = this.options.weatherDatasets[i].options.findIndex(x => x.id == this.form.weatherDatasetID);
					if (index > -1) {
						var dataset = this.options.weatherDatasets[i].options[index];
						this.weatherOptions.selectedDataset = dataset;
						this.weatherOptions.minDate = moment(dataset.startDate).format('YYYY-MM-DD');
						this.weatherOptions.maxDate = moment(dataset.endDate).format('YYYY-MM-DD');
						this.weatherOptions.showClimateChange = !this.isNullOrEmpty(dataset.cmipVersion);

						if (setDefaults) {
							this.form.startingSimulationDate = this.weatherOptions.minDate;
							var maxDate = moment(this.weatherOptions.minDate).add(5, 'y').subtract(1, 'd');
							if (moment(this.weatherOptions.maxDate).isBefore(maxDate)) {
								maxDate = this.weatherOptions.maxDate;
							}
							this.form.endingSimulationDate = moment(maxDate).format('YYYY-MM-DD');

							if (this.weatherOptions.showClimateChange) {
								this.form.climateChangeScenarioID = this.climateScenarioOptions[0].id;
								this.form.climateChangeTimePeriodID = this.climateTimePeriodOptions[0].id;
							}
						} else {
							this.form.startingSimulationDate = moment(this.data.startingSimulationDate).format('YYYY-MM-DD');
							this.form.endingSimulationDate = moment(this.data.endingSimulationDate).format('YYYY-MM-DD');
						}					
					}
				}
			},
			updateClimateTimePeriod() {
				var index = this.options.climateChangeTimePeriods.findIndex(x => x.id == this.form.climateChangeTimePeriodID);
				if (index > -1) {
					var dataset = this.options.climateChangeTimePeriods[index];
					this.weatherOptions.minDate = moment(dataset.startDate).format('YYYY-MM-DD');
					this.weatherOptions.maxDate = moment(dataset.endDate).format('YYYY-MM-DD');
					this.form.startingSimulationDate = this.weatherOptions.minDate;
					this.form.endingSimulationDate = this.weatherOptions.maxDate;
				}
			},
			cancel() {
				this.$emit('saved', 'cancel');
			}
		},
		computed: {
			climateScenarioOptions() {
				if (this.weatherOptions.showClimateChange) {
					var selectedCMIP = this.weatherOptions.selectedDataset.cmipVersion;
					return this.options.climateChangeScenarios.filter(function (el) { return el.cmipVersion == selectedCMIP; });
				}

				return this.options.climateChangeScenarios;
			},
			climateTimePeriodOptions() {
				if (this.weatherOptions.showClimateChange) {
					var selectedCMIP = this.weatherOptions.selectedDataset.cmipVersion;
					return this.options.climateChangeTimePeriods.filter(function (el) { return el.cmipVersion == selectedCMIP; });
				}

				return this.options.climateChangeTimePeriods;
			}
		}
	}
</script>
