import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Help from './views/Help.vue';
import HelpLanduse from './views/HelpLanduse.vue';

import AccountProfile from './views/account/AccountProfile.vue';
import AccountConfirm from './views/account/AccountConfirm.vue';
import AccountReset from './views/account/AccountReset.vue';
import AccountChangeEmail from './views/account/AccountChangeEmail.vue';

import FeedbackList from './views/account/feedback/FeedbackList.vue';
import FeedbackView from './views/account/feedback/FeedbackView.vue';
import FeedbackCreate from './views/account/feedback/FeedbackCreate.vue';

import HruTable from './views/projects/HruTable.vue';
import ProjectList from './views/projects/ProjectList.vue';
import ProjectCreate from './views/projects/ProjectCreate.vue';
import ProjectView from './views/projects/ProjectView.vue';
import ProjectHrus from './views/projects/ProjectHrus.vue';
import ProjectDocuments from './views/projects/ProjectDocuments.vue';
import ProjectMetadata from './views/projects/ProjectMetadata.vue';
import ProjectDownloads from './views/projects/ProjectDownloads.vue';
import ScenarioList from './views/projects/scenarios/ScenarioList.vue';
import ScenarioView from './views/projects/scenarios/ScenarioView.vue';
import ScenarioCreate from './views/projects/scenarios/ScenarioCreate.vue';

import ErrorReportList from './views/projects/errors/ErrorReportList.vue';
import ErrorReportView from './views/projects/errors/ErrorReportView.vue';
import ErrorReportCreate from './views/projects/errors/ErrorReportCreate.vue';

import EditBsn from './views/projects/scenarios/edit/general/EditBsn.vue';
import EditFerts from './views/projects/scenarios/edit/general/EditFerts.vue';
import EditFert from './views/projects/scenarios/edit/general/EditFert.vue';
import EditUrbans from './views/projects/scenarios/edit/general/EditUrbans.vue';
import EditUrban from './views/projects/scenarios/edit/general/EditUrban.vue';
import EditNutrientEfficiency from './views/projects/scenarios/edit/general/EditNutrientEfficiency.vue';
import EditLup from './views/projects/scenarios/edit/general/EditLup.vue';
import EditCalibration from './views/projects/scenarios/edit/general/EditCalibration.vue';

import EditWgn from './views/projects/scenarios/edit/weather/EditWgn.vue';
import EditClimateSensitivity from './views/projects/scenarios/edit/weather/EditClimateSensitivity.vue';

import EditMgtGeneral from './views/projects/scenarios/edit/mgt/EditMgtGeneral.vue';
import EditMgtOperations from './views/projects/scenarios/edit/mgt/EditMgtOperations.vue';

import EditCurveNumber from './views/projects/scenarios/edit/subbasin/EditCurveNumber.vue';
import EditSedimentRouting from './views/projects/scenarios/edit/subbasin/EditSedimentRouting.vue';
import EditPointSource from './views/projects/scenarios/edit/subbasin/EditPointSource.vue';
import EditPotholes from './views/projects/scenarios/edit/subbasin/EditPotholes.vue';

import EditHruVars from './views/projects/scenarios/edit/print/EditHruVars.vue';
import EditHruPrint from './views/projects/scenarios/edit/print/EditHruPrint.vue';

import OutputMaps from './views/projects/scenarios/output/OutputMaps.vue';
import OutputCharts from './views/projects/scenarios/output/OutputCharts.vue';
import OutputCheck from './views/projects/scenarios/output/OutputCheck.vue';
import OutputCeQual from './views/projects/scenarios/output/OutputCeQual.vue';
import OutputFfa from './views/projects/scenarios/output/OutputFfa.vue';
import OutputBaseflowSeparation from './views/projects/scenarios/output/OutputBaseflowSeparation.vue';

import GroupList from './views/groups/GroupList.vue';
import GroupInvites from './views/groups/GroupInvites.vue';
import GroupView from './views/groups/GroupView.vue';
import GroupCreate from './views/groups/GroupCreate.vue';

import DatasetList from './views/datasets/DatasetList.vue';
import DatasetView from './views/datasets/DatasetView.vue';
import DatasetCreate from './views/datasets/DatasetCreate.vue';

import AdminDashboard from './views/admin/AdminDashboard.vue';
import AdminDatabase from './views/admin/AdminDatabase.vue';
import AdminReports from './views/admin/AdminReports.vue';
import AdminUsers from './views/admin/users/AdminUsers.vue';
import AdminUser from './views/admin/users/AdminUser.vue';
import AdminUserCreate from './views/admin/users/AdminUserCreate.vue';
import AdminGroups from './views/admin/users/groups/AdminGroups.vue';
import AdminGroupView from './views/admin/users/groups/AdminGroupView.vue';
import AdminTiers from './views/admin/users/tiers/AdminTiers.vue';
import AdminTierView from './views/admin/users/tiers/AdminTierView.vue';
import AdminTierCreate from './views/admin/users/tiers/AdminTierCreate.vue';
import AdminUserCategories from './views/admin/users/AdminUserCategories.vue';
import AdminErrors from './views/admin/errors/AdminErrors.vue';
import AdminErrorView from './views/admin/errors/AdminErrorView.vue';
import AdminProjects from './views/admin/projects/AdminProjects.vue';
import AdminScenarios from './views/admin/projects/AdminScenarios.vue';
import AdminDatasets from './views/admin/datasets/AdminDatasets.vue';
import AdminDatasetView from './views/admin/datasets/AdminDatasetView.vue';
import AdminWebsiteText from './views/admin/AdminWebsiteText.vue';
import AdminWebsiteTextPage from './views/admin/AdminWebsiteTextPage.vue';
import AdminWebsiteSettings from './views/admin/AdminWebsiteSettings.vue';
import AdminJobs from './views/admin/AdminJobs.vue';
import AdminImportHuc2 from './views/admin/AdminImportHuc2.vue';
import AdminWeatherList from './views/admin/datasets/weather/AdminWeatherList.vue';
import AdminWeatherView from './views/admin/datasets/weather/AdminWeatherView.vue';
import AdminWeatherCreate from './views/admin/datasets/weather/AdminWeatherCreate.vue';

import AdminSwatVersionList from './views/admin/datasets/swatversions/AdminSwatVersionList.vue';
import AdminSwatVersionView from './views/admin/datasets/swatversions/AdminSwatVersionView.vue';
import AdminSwatVersionCreate from './views/admin/datasets/swatversions/AdminSwatVersionCreate.vue';

import Version1CheckUser from './views/v1/Version1CheckUser.vue';
import Version1VerifyUser from './views/v1/Version1VerifyUser.vue';

import ApiDashboard from './views/api/ApiDashboard.vue';
import ApiBatchList from './views/api/batch/ApiBatchList.vue';
import ApiBatchCreate from './views/api/batch/ApiBatchCreate.vue';
import ApiBatchView from './views/api/batch/ApiBatchView.vue';
import ApiRegionalCreate from './views/api/regional/ApiRegionalCreate.vue';

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/', name: 'Home', component: Home
		},
		{
			path: '/help', name: 'Help', component: Help, meta: { title: 'Documentation & Support' },
			children: [
				{
					path: 'landuse', name: 'HelpLanduse', component: HelpLanduse, meta: { title: 'Land Use Definitions' }
				}
			]
		},
		{
			path: '/v1/check', name: 'Version1CheckUser', component: Version1CheckUser
		},
		{
			path: '/v1/verify/:code', name: 'Version1VerifyUser', component: Version1VerifyUser
		},
		{
			path: '/account', name: 'AccountProfile', component: AccountProfile,
			children: [
				{
					path: 'confirm/:userId/:code', name: 'AccountConfirm', component: AccountConfirm
				},
				{
					path: 'reset/:code', name: 'AccountReset', component: AccountReset
				},
				{
					path: 'changeemail/:userId/:code/:email', name: 'AccountChangeEmail', component: AccountChangeEmail
				}
			]
		},
		{
			path: '/groups', name: 'GroupList', component: GroupList, meta: { title: 'Groups' },
			children: [
				{
					path: 'invites', name: 'GroupInvites', component: GroupInvites, meta: { title: 'Invitations' }
				},
				{
					path: 'create', name: 'GroupCreate', component: GroupCreate, meta: { title: 'Create' }
				},
				{
					path: ':id', name: 'GroupView', component: GroupView, meta: { title: 'View' }
				}
			]
		},
		{
			path: '/feedback', name: 'FeedbackList', component: FeedbackList, meta: { title: 'Feedbacks' },
			children: [
				{
					path: 'create', name: 'FeedbackCreate', component: FeedbackCreate, meta: { title: 'Create' }
				},
				{
					path: ':reportID', name: 'FeedbackView', component: FeedbackView, meta: { title: 'View' }
				}
			]
		},
		{
			path: '/datasets', name: 'DatasetList', component: DatasetList, meta: { title: 'Project Datasets' },
			children: [
				{
					path: 'create', name: 'DatasetCreate', component: DatasetCreate, meta: { title: 'Upload' }
				},
				{
					path: ':id', name: 'DatasetView', component: DatasetView, meta: { title: 'View' }
				}
			]
		},		
		{
			path: '/hrus/:id', name: 'HruTable', component: HruTable
		},
		{
			path: '/projects', name: 'ProjectList', component: ProjectList, meta: { title: 'Projects' },
			children: [
				{
					path: 'create', name: 'ProjectCreate', component: ProjectCreate
				},
				{
					path: ':id', name: 'ProjectView', component: ProjectView, meta: { title: 'View' },
					children: [
						{
							path: 'hrus', name: 'ProjectHrus', component: ProjectHrus, meta: { title: 'HRUs' }
						},
						{
							path: 'scenarios', name: 'ScenarioList', component: ScenarioList, meta: { title: 'Scenarios' },
							children: [
								{
									path: 'create', name: 'ScenarioCreate', component: ScenarioCreate, meta: { title: 'Create' }
								},
								{
									path: ':scenarioID', name: 'ScenarioView', component: ScenarioView, meta: { title: 'Scenario' },
									children: [
										{
											path: 'bsn', name: 'EditBsn', component: EditBsn, meta: { title: 'Basin' }
										},
										{
											path: 'fert', name: 'EditFerts', component: EditFerts, meta: { title: 'Fertilizers' },
											children: [
												{
													path: ':fertID', name: 'EditFert', component: EditFert, meta: { title: 'Edit' }
												}
											]
										},
										{
											path: 'nutrient-efficiency', name: 'EditNutrientEfficiency', component: EditNutrientEfficiency, meta: { title: 'Nutrient Efficiency' }
										},
										{
											path: 'lup', name: 'EditLup', component: EditLup, meta: { title: 'Land Use Update' }
										},
										{
											path: 'urban', name: 'EditUrbans', component: EditUrbans, meta: { title: 'Urban' },
											children: [
												{
													path: ':urbanID', name: 'EditUrban', component: EditUrban, meta: { title: 'Edit' }
												}
											]
										},
										{
											path: 'calibration', name: 'EditCalibration', component: EditCalibration, meta: { title: 'Calibration Data' }
										},
										{
											path: 'mgt-general', name: 'EditMgtGeneral', component: EditMgtGeneral, meta: { title: 'General Management' }
										},
										{
											path: 'mgt-operations', name: 'EditMgtOperations', component: EditMgtOperations, meta: { title: 'Operations Management' }
										},
										{
											path: 'curve-number', name: 'EditCurveNumber', component: EditCurveNumber, meta: { title: 'Curve Number' }
										},
										{
											path: 'sediment-routing', name: 'EditSedimentRouting', component: EditSedimentRouting, meta: { title: 'Sediment Routing Method' }
										},
										{
											path: 'point-source', name: 'EditPointSource', component: EditPointSource, meta: { title: 'Point Source' }
										},
										{
											path: 'potholes', name: 'EditPotholes', component: EditPotholes, meta: { title: 'Pothole Variables' }
										},
										{
											path: 'climate-sensitivity', name: 'EditClimateSensitivity', component: EditClimateSensitivity, meta: { title: 'Climate Sensitivity/Variability Analysis' }
										},
										{
											path: 'weather-generator', name: 'EditWgn', component: EditWgn, meta: { title: 'Weather Generator' }
										},
										{
											path: 'hru-vars', name: 'EditHruVars', component: EditHruVars, meta: { title: 'HRU Variables to Print' }
										},
										{
											path: 'hru-print', name: 'EditHruPrint', component: EditHruPrint, meta: { title: 'HRUs to Print' }
										},
										{
											path: 'output/maps', name: 'OutputMaps', component: OutputMaps, meta: { title: 'Output Maps' }
										},
										{
											path: 'output/charts', name: 'OutputCharts', component: OutputCharts, meta: { title: 'Output Charts' }
										},
										{
											path: 'output/swat-check', name: 'OutputCheck', component: OutputCheck, meta: { title: 'SWAT Check' }
										},
										{
											path: 'output/ce-qual', name: 'OutputCeQual', component: OutputCeQual, meta: { title: 'CE-QUAL' }
										},
										{
											path: 'output/ffa', name: 'OutputFfa', component: OutputFfa, meta: { title: 'Flood Frequency Analysis' }
										},
										{
											path: 'output/baseflowseparation', name: 'OutputBaseflowSeparation', component: OutputBaseflowSeparation, meta: { title: 'Baseflow Separation' }
										}
									]
								}
							]
						},
						{
							path: 'documents', name: 'ProjectDocuments', component: ProjectDocuments, meta: { title: 'Uploaded Documents' }
						},
						{
							path: 'metadata', name: 'ProjectMetadata', component: ProjectMetadata, meta: { title: 'Metadata' }
						},
						{
							path: 'downloads', name: 'ProjectDownloads', component: ProjectDownloads, meta: { title: 'Downloads' }
						},
						{
							path: 'errors', name: 'ErrorReportList', component: ErrorReportList, meta: { title: 'Error Reports' },
							children: [
								{
									path: 'create', name: 'ErrorReportCreate', component: ErrorReportCreate, meta: { title: 'Create' }
								},
								{
									path: ':reportID', name: 'ErrorReportView', component: ErrorReportView, meta: { title: 'View' }
								}
							]
						},
					]
				}
			]
		},
		{
			path: '/admin', name: 'AdminDashboard', component: AdminDashboard, meta: { title: 'Admin Dashboard' },
			children: [
				{
					path: 'users', name: 'AdminUsers', component: AdminUsers, meta: { title: 'Users' },
					children: [
						{
							path: 'tiers', name: 'AdminTiers', component: AdminTiers, meta: { title: 'Tiers' },
							children: [
								{
									path: 'create', name: 'AdminTierCreate', component: AdminTierCreate, meta: { title: 'Create' }
								},
								{
									path: ':id', name: 'AdminTierView', component: AdminTierView, meta: { title: 'Details' }
								}
							]
						},
						{
							path: 'groups', name: 'AdminGroups', component: AdminGroups, meta: { title: 'Groups' },
							children: [
								{
									path: ':id', name: 'AdminGroupView', component: AdminGroupView, meta: { title: 'Details' }
								}
							]
						},
						{
							path: 'categories', name: 'AdminUserCategories', component: AdminUserCategories, meta: { title: 'Categories' }
						},
						{
							path: 'create', name: 'AdminUserCreate', component: AdminUserCreate, meta: { title: 'Create' }
						},
						{
							path: ':id', name: 'AdminUser', component: AdminUser, meta: { title: 'Account Information' }
						}
					]
				},
				{
					path: 'projects', name: 'AdminProjects', component: AdminProjects, meta: { title: 'Projects' },
					children: [
						{
							path: 'scenarios', name: 'AdminScenarios', component: AdminScenarios, meta: { title: 'Scenarios' }
						}
					]
				},
				{
					path: 'errors', name: 'AdminErrors', component: AdminErrors, meta: { title: 'Error Reports' },
					children: [
						{
							path: ':id', name: 'AdminErrorView', component: AdminErrorView, meta: { title: 'Update' }
						}
					]
				},
				{
					path: 'reports', name: 'AdminReports', component: AdminReports, meta: { title: 'Reports' }
				},
				{
					path: 'database', name: 'AdminDatabase', component: AdminDatabase, meta: { title: 'Database' }
				},
				{
					path: 'datasets', name: 'AdminDatasets', component: AdminDatasets, meta: { title: 'Datasets' },
					children: [
						{
							path: 'weather', name: 'AdminWeatherList', component: AdminWeatherList, meta: { title: 'Weather' },
							children: [
								{
									path: 'create', name: 'AdminWeatherCreate', component: AdminWeatherCreate, meta: { title: 'Create' }
								},
								{
									path: ':id', name: 'AdminWeatherView', component: AdminWeatherView, meta: { title: 'Details' }
								}
							]
						},
						{
							path: 'swatversions', name: 'AdminSwatVersionList', component: AdminSwatVersionList, meta: { title: 'SWAT Versions' },
							children: [
								{
									path: 'create', name: 'AdminSwatVersionCreate', component: AdminSwatVersionCreate, meta: { title: 'Create' }
								},
								{
									path: ':id', name: 'AdminSwatVersionView', component: AdminSwatVersionView, meta: { title: 'Details' }
								}
							]
						},
						{
							path: ':id', name: 'AdminDatasetView', component: AdminDatasetView, meta: { title: 'Details' }
						}
					]
				},
				{
					path: 'website', name: 'AdminWebsiteText', component: AdminWebsiteText, meta: { title: 'Website Text' },
					children: [
						{
							path: ':id', name: 'AdminWebsiteTextPage', component: AdminWebsiteTextPage, meta: { title: 'Page' }
						}
					]
				},
				{
					path: 'website-settings', name: 'AdminWebsiteSettings', component: AdminWebsiteSettings, meta: { title: 'Website Settings' }
				},
				{
					path: 'special-jobs', name: 'AdminJobs', component: AdminJobs, meta: { title: 'Special Jobs' }
				},
				{
					path: 'import-huc-v2', name: 'AdminImportHuc2', component: AdminImportHuc2, meta: { title: 'Import HUC Data Version 2' }
				}
			]
		},
		{
			path: '/api', name: 'ApiDashboard', component: ApiDashboard, meta: { title: 'Advanced Features' },
			children: [
				{
					path: 'batch', name: 'ApiBatchList', component: ApiBatchList, meta: { title: 'Batch Projects' },
					children: [
						{
							path: 'create', name: 'ApiBatchCreate', component: ApiBatchCreate, meta: { title: 'Create' }
						},
						{
							path: ':id', name: 'ApiBatchView', component: ApiBatchView, meta: { title: 'View' }
						}
					]
				},
				{
					path: 'regional', name: 'ApiRegionalCreate', component: ApiRegionalCreate, meta: { title: 'Regional Projects' }
				}
			]
		},
		{
			path: '*',
			redirect: '/'
		}
	]
})
