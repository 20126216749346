<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<b-alert :show="siteSettings.enableAlertNotification" variant="warning" class="mb-0">
				{{ siteSettings.alertNotification }}
			</b-alert>
			<div id="main-panel">
				<page-authorization-container :page="page" require-admin>
					<b-container fluid class="p-0">
						<b-row no-gutters>
							<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
								<div class="px-3 pt-md-4">
									<b-nav vertical class="dash-items-nav nav-lg">
										<b-nav-item to="/admin"><font-awesome-icon :icon="['fas', 'tachometer-alt']" fixed-width class="mr-3" /> Dashboard</b-nav-item>
										<b-nav-item to="/admin/users">
											<font-awesome-icon :icon="['fas', 'user-friends']" fixed-width class="mr-3" /> Users
											<b-nav vertical v-if="$route.path.startsWith('/admin/users')" class="nested">
												<b-nav-item to="/admin/users/tiers">Tiers</b-nav-item>
												<b-nav-item to="/admin/users/groups">Groups</b-nav-item>
												<b-nav-item to="/admin/users/categories">Categories</b-nav-item>
											</b-nav>
										</b-nav-item>
										<b-nav-item to="/admin/projects">
											<font-awesome-icon :icon="['fas', 'project-diagram']" fixed-width class="mr-3" /> Projects
											<b-nav vertical v-if="$route.path.startsWith('/admin/projects')" class="nested">
												<b-nav-item to="/admin/projects/scenarios">Scenarios</b-nav-item>
											</b-nav>
										</b-nav-item>
										<b-nav-item to="/admin/datasets">
											<font-awesome-icon :icon="['fas', 'database']" fixed-width class="mr-3" /> Datasets
											<b-nav vertical v-if="$route.path.startsWith('/admin/datasets')" class="nested">
												<b-nav-item to="/admin/datasets/weather">Weather</b-nav-item>
												<b-nav-item to="/admin/datasets/swatversions">SWAT Versions</b-nav-item>
											</b-nav>
										</b-nav-item>
										<b-nav-item to="/admin/reports"><font-awesome-icon :icon="['fas', 'table']" fixed-width class="mr-3" /> Reports</b-nav-item>
										<b-nav-item to="/admin/errors"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-3" /> Feedback/errors</b-nav-item>
									</b-nav>

									<div v-if="isAuthorized(roleNames.admin)" class="mt-4">
										<h6>Advanced</h6>
										<b-nav vertical class="dash-items-nav nav-lg">
											<b-nav-item :href="`/jobs?access_token=${localStorageToken}`" target="_blank"><font-awesome-icon :icon="['fas', 'layer-group']" fixed-width class="mr-3" /> Job queue</b-nav-item>
											<b-nav-item to="/admin/website">
												<font-awesome-icon :icon="['far', 'file-alt']" fixed-width class="mr-3" /> Website text
												<b-nav vertical v-if="$route.path.startsWith('/admin/website') && !$route.path.startsWith('/admin/website-')" class="nested">
													<b-nav-item to="/admin/website/Help">Help page</b-nav-item>
													<b-nav-item to="/admin/website/PointSource">Point source page</b-nav-item>
												</b-nav>
											</b-nav-item>
											<b-nav-item to="/admin/website-settings">
												<font-awesome-icon :icon="['fas', 'cog']" fixed-width class="mr-3" /> Website settings
											</b-nav-item>
											<b-nav-item to="/admin/import-huc-v2" v-if="data.showImportV2">
												<font-awesome-icon :icon="['fas', 'database']" fixed-width class="mr-3" /> Import HUC v2
											</b-nav-item>
										</b-nav>
									</div>
								</div>
							</b-col>
							<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
								<b-breadcrumb :items="generalBreadCrumbs" class="no-curves bg-white px-md-4"></b-breadcrumb>
								<div class="container-fluid px-md-4 pb-4">
									<div v-if="$route.name == 'AdminDashboard'">
										<div class="text-muted mb-3 border-bottom pb-2">
											<small>
												<font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
												The statistics on this page were computed at
												{{ data.computedAt | date }}.
												<a href="#" @click.prevent="get">Refresh now.</a>
											</small>
										</div>

										<div class="mb-4">
											<highcharts :options="statisticsTimeChart"></highcharts>
										</div>

										<h2 class="h4 text-center">Totals</h2>
										<hr class="mt-2 mb-3" />
										<b-row align-h="center">
											<b-col cols="6" lg="3">
												<div class="stat-box bg-primary text-white mb-2">
													<div class="title">Users</div>
													<div class="value">{{ data.numUsers |  number(0) }}</div>
												</div>
											</b-col>
											<b-col cols="6" lg="3">
												<div class="stat-box bg-primary text-white mb-2">
													<div class="title">Projects</div>
													<div class="value">{{ data.numProjects |  number(0) }}</div>
												</div>
											</b-col>
											<b-col cols="6" lg="3">
												<div class="stat-box bg-primary text-white mb-2">
													<div class="title">Scenarios</div>
													<div class="value">{{ data.numScenarios |  number(0) }}</div>
												</div>
											</b-col>
											<b-col cols="6" lg="3">
												<div class="stat-box bg-primary text-white mb-2">
													<div class="title">Running Now</div>
													<div class="value">
														{{ data.numScenariosRunning |  number(0) }}
														<span :title="`As of ${toDate(data.computedAt)}. Click to refresh.`">
															<a class="mute" href="#" @click.prevent="get">
																<font-awesome-icon :icon="['far', 'clock']" @click="get" />
															</a>
														</span>
													</div>
												</div>
											</b-col>
										</b-row>

										<h2 class="h4 text-center mt-4">Averages</h2>
										<hr class="mt-2 mb-3" />
										<b-row align-h="center">
											<b-col cols="6" lg="3">
												<div class="stat-box bg-info text-white mb-2">
													<div class="title">Projects / User</div>
													<div class="value">{{ data.avgProjectsPerUser |  number(1) }}</div>
												</div>
											</b-col>
											<b-col cols="6" lg="3">
												<div class="stat-box bg-info text-white mb-2">
													<div class="title">HRUs / Project</div>
													<div class="value">{{ data.avgHrusPerProject |  number(1) }}</div>
												</div>
											</b-col>
											<b-col cols="6" lg="3">
												<div class="stat-box bg-info text-white mb-2">
													<div class="title">Scenarios / Project</div>
													<div class="value">{{ data.avgScenariosPerProject |  number(1) }}</div>
												</div>
											</b-col>
											<b-col cols="6" lg="3">
												<div class="stat-box bg-info text-white mb-2">
													<div class="title">Scenario Run Time</div>
													<div class="value">
														{{ data.avgScenarioRunTime |  number(1) }}
														<span title="minutes">min</span>
													</div>
												</div>
											</b-col>
										</b-row>

										<hr class="mt-2 mb-3" />
										<p>
											Code version: <strong>{{ data.buildDate | date }}</strong>.
										</p>
									</div>
									<router-view></router-view>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</page-authorization-container>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';

	export default {
		name: 'AdminDashboard',
		components: {
			DashboardHeader
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {
					statisticsTimeChart: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/dashboard', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			statisticsTimeChart() {
				return {
					chart: { type: 'bar' },
					plotOptions: { bar: { dataLabels: { enabled: true } } },
					title: { text: this.data.statisticsTimeChart.title },
					xAxis: { categories: this.data.statisticsTimeChart.xAxisCategories },
					yAxis: { title: { text: '' } },
					series: this.data.statisticsTimeChart.series
				};
			}
		}
	}
</script>
